"use client";

import { useState } from "react";
import { Icons } from "../icons";

const NavAlertBar = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null; // If not visible, render nothing

  return (
    <div className="bg-button-primary inline-flex w-full items-center justify-between rounded-lg p-1.5">
      <div className="flex h-5 shrink grow basis-0 items-center justify-center gap-2 text-white text-sm leading-tight">
        <div className="font-bold">Join our open beta!</div>
        <a className="underline" href="/docs/getting-started">
          Installation Guide
        </a>
      </div>
      <div
        className="text-text-on-color flex cursor-pointer items-center justify-center text-base"
        onClick={handleClose} // Ensure onClick is set directly on the container
        role="button"
      >
        <Icons.close className="text-base" />
      </div>
    </div>
  );
};

export default NavAlertBar;
